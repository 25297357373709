import React, { Children, useId } from 'react';

import Underline from '@components/ui/Underline';

import styles from './PostGroup.module.css';

interface PostGroupProps {
    title: string;
}

export default function PostGroup({ title, children }: React.PropsWithChildren<PostGroupProps>): JSX.Element {
    const childrenArray = Children.toArray(children);
    const id = useId();

    return (
        <aside
            className={styles.group}
            aria-labelledby={id}
        >
            <h2
                className={styles.header}
                id={id}
            >
                <Underline>{title}</Underline>
            </h2>
            <ul>
                {childrenArray.map((Child, i) => {
                    return <li key={i}>{Child}</li>;
                })}
            </ul>
        </aside>
    );
}
