import * as React from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';

import { canonical } from '@helpers/alternates';

import useTranslation from '@hooks/useTranslation';
import { laravelRouteBlogSingle } from '@static_components/laravelLinks';

interface BlogPost {
    primary_tag?: { name: string; slug: string };
    image?: string;
    publish_date: string;
    is_sticky: boolean;
    title: string;
    post_tags?: Array<string>;
    slug: string;
    excerpt: string;
}

interface JsonLdWrapperProps {
    posts: BlogPost[];
}

export default function JsonLdWrapper({ posts }: JsonLdWrapperProps) {
    const { t } = useTranslation('pages/blog/index');
    const { locale, pathname } = useRouter();
    const canonicalUrl = canonical(pathname, locale);
    const items = posts.map((post, i) => ({
        '@type': 'ListItem',
        position: i + 1,
        name: post.title,
        url: laravelRouteBlogSingle(locale).replace('{slug}', post.slug),
        image: post.image ?? undefined,
    }));

    const website = {
        '@context': 'https://schema.org',
        '@graph': [
            {
                '@type': 'WebPage',
                '@id': canonicalUrl + '#webpage',
                url: canonicalUrl,
                name: t('seo_title'),
                description: t('seo_description'),
                potentialAction: [
                    {
                        '@type': 'ReadAction',
                        target: [canonicalUrl],
                    },
                ],
            },
            {
                '@type': 'ItemList',
                itemListElement: items,
            },
        ],
    };

    return (
        <Head>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(website),
                }}
            />
        </Head>
    );
}
